<template>
  <div>
    <wysiwyg v-model="body" class="box-shadow radius" />
    <div class="cont-btn" v-if="!sending && body">
      <div class="btn-green radius" @click="send" >Send message</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewMessageReply',
  props: ['recepient'],
  data() {
    return {
      body: '',
      sending: false,
    };
  },
  methods: {
    send() {
      this.sending = true;
      this.$store.dispatch('user/sendMessage', { message: { body: this.body }, recepientId: this.recepient._id }).then(() => {
        this.sending = false;
        this.body = '';
      });
    },
  },
};
</script>

<style scoped lang="scss">
  .editr{
    border: 0;
  }
  .cont-btn{
    width: 100%;
    margin: 10px 0;
    .btn-green{
      margin: 0;
      margin-right: 20px;
      // width: 150px;
    }
  }
</style>
