<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          Messages
        </div>
      </div>
    </div>
    <div class="inner-container" v-if="loaded">
        <div class="flex-container flex-2-tier-sdfeed">
          <div class="elem-flex">
            <div class="flex-container flex-2-tier-sdlarge reverse">
              <div class="elem-flex mhspe flex-overflow-column">
                <div class="subtitle-page">
                  <div
                    class="elem-search message-search"
                  >
                    <input
                      type="text"
                      placeholder="Search"
                      class="radius box-shadow head-search"
                      v-model="searchString"
                      @input="search"
                    >
                    <div
                      class="picto-search"
                    >
                      <img class=""
                        :src="require('@/assets/pictos/picto_search_darkpurple.svg')"
                      >
                    </div>

                  </div>
                </div>
                <div class="list-conv">
                  <div
                    class="conv radius box-shadow"
                    v-for="conversation in this.shownConversations"
                    :key="conversation._id"
                    :class="(conversation._id === selectedConversationId ? 'selected' : '')"
                    @click="() => select(conversation._id, conversation.seen)">
                    <div class="line" :class="(conversation.seen ? 'seen' : 'unseen')"></div>
                    <!-- {{conversation}} -->
                    <div class="cont-mess">
                      <div class="user-mess">{{nameString(conversation.user.name)}}
                        <i>{{conversation.user.company
                          ?
                          `(${conversation.user.company.name})`:
                          ''}}</i>
                      </div>
                      <div class="subject-mess" v-if="conversation.messages[0].subject">
                        {{conversation.messages[0].subject}}
                      </div>
                      <div class="last-mess">
                        <p v-html="lastmessage(conversation)">
                          <!-- {{lastmessage(conversation)}} -->
                        </p>
                      </div>
                    </div>
                    <!-- <div
                      class="global-param"
                      @click="deleteConversationbyID(conversation._id)"
                      v-tooltip.top-center="{
                        content: 'Delete',
                        delay: {
                          show: 0,
                          hide: 0,
                        },
                      }"
                    >
                      <div class="param ghost"></div>
                      <div class="param">
                        <img src="@/assets/pictos/picto_param_purple.svg">
                        <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg">
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="elem-flex mhspe flex-overflow-column">
                <div class="list-messages" v-if="selectedConversationId">
                    <div class="head-conv">
                      <div class="left-head">
                        <div class="name" v-if="conversationInfo.user.__t === 'Employee'">
                          {{conversationInfo.user.name.firstName}}
                          {{conversationInfo.user.name.lastName}}

                          <i>({{conversationInfo.user.company.name}})</i>
                        </div>
                        <div class="name" v-else>
                          {{conversationInfo.user.name.firstName}}
                          {{conversationInfo.user.name.lastName}}
                        </div>
                        <div class="lastdate">
                          {{calcDateFromNow(conversationInfo
                          .messages[conversationInfo.messages.length - 1]
                          .timeSent)}}
                        </div>
                      </div>
                      <div class="right-head">
                        <!-- {{conversationInfo}} -->
                        <!-- <button @click="deleteConversation">Delete Conversation</button> -->
                        <div
                          class="global-param"
                          @click="deleteConversation"
                          v-tooltip.top-center="{
                            content: 'Delete',
                            delay: {
                              show: 0,
                              hide: 0,
                            },
                          }"
                        >
                          <div class="param ghost"></div>
                          <div class="param">
                            <img :src="require('@/assets/pictos/picto_purple_trash.svg')" />
                          </div>
                        </div>
                        <router-link
                          class="btn-green radius small"
                          v-if="conversationInfo.user.__t === 'Talent'"
                          :to="`/talent/${conversationInfo.user._id}`"
                        >
                          See profile
                        </router-link>
                        <router-link
                          class="btn-green radius small"
                          v-else
                          :to="`/company/${conversationInfo.user.company._id}`"
                        >
                          See company profile
                        </router-link>

                      </div>
                    </div>
                    <div class="over-cont-message radius box-shadow" id="scroll-over-cont-message">
                      <div class="cont-messages">
                        <!-- {{conversationMessages}} -->
                        <div
                          v-for="message in conversationMessages"
                          class="message"
                          :key="message._id"
                          :class="messageSender(message) === 'Me: ' ? 'me' : 'other'"
                        >
                          <div class="body-message radius box-shadow" v-html="message.body">
                            {{message.body}}
                          </div>
                          <div class="date-message">
                            {{message.timeSent | moment('HH:mm a DD/MM/YY')}}
                          </div>
                        </div>
                      </div>
                    </div>
                  <div class="cont-write">
                    <new-message-reply v-if="selectedRecepient" :recepient="selectedRecepient" />
                  </div>
                </div>
                <div class="no-message radius" v-else>
                  No Message Selected
                </div>
              </div>
            </div>
          </div>
          <div class="elem-flex">
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import NewMessageReply from '@/components/UniversalComponents/Messages/newMessageReply.vue';
import calcDateFromNow from '@/modules/mixins/calcDateFromNow';

export default {
  name: 'Messages',
  components: {
    NewMessageReply,
  },
  mixins: [calcDateFromNow],
  data() {
    return {
      searchString: '',
      shownConversations: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('user', ['conversations', 'selectedConversationId', '_id', 'userInfo']),
    ...mapState('auth', ['role']),
    conversationMessages() {
      const val = this.$store.getters['user/conversationSelected'];
      if (val) {
        return val.messages;
      }
      return [];
    },
    conversationInfo() {
      const val = this.$store.getters['user/conversationSelected'];
      if (val) {
        return val;
      }
      return [];
    },
    selectedRecepient() {
      const val = this.$store.getters['user/conversationSelected'];
      if (val) {
        return val.user;
      }
      return [];
    },
  },
  methods: {
    search() {
      if (this.searchString) {
        const searchStr = this.searchString.toLowerCase();
        this.shownConversations = this.conversations.filter((conv) => {
          if (this.nameString(conv.user.name).toLowerCase().includes(`${searchStr}`)) {
            return true;
          } if (conv.user.company
            && conv.user.company.name.toLowerCase().includes(`${searchStr}`)
          ) {
            return true;
          }
          return false;
        });
      } else {
        this.shownConversations = this.conversations;
      }
    },
    nameString(name) {
      return `${name.firstName || ''} ${name.lastName || ''}`;
    },
    select(id, seen) {
      if (!seen) {
        this.$store.dispatch('user/seenConversation', id);
      }
      this.$store.dispatch('user/selectConversation', id);
    },
    messageSender(message) {
      if (message.sender === this._id) {
        return 'Me: ';
      }
      return this.nameString(this.$store.getters['user/conversationSelected'].user.name);
    },
    lastmessage(conversation) {
      const convo = conversation.messages;
      return convo[convo.length - 1].body;
    },
    goUserPage() {

    },
    deleteConversation() {
      const conversationId = this.$store.getters['user/conversationSelected']._id;
      this.$store.dispatch('user/deleteConversation', conversationId);
    },
    deleteConversationbyID(id) {
      const conversationId = id;
      this.$store.dispatch('user/deleteConversation', conversationId).then(() => {
        // document.location.reload();
      });
    },
  },
  mounted() {
    if (this.conversations.length > 0) {
      this.loaded = true;
    }
    this.$store.dispatch('user/getConversations').then(() => {
      this.shownConversations = [...this.conversations];
      this.loaded = true;
    });
    const selected = this.$store.getters['user/conversationSelected'];
    if (selected && !selected.seen) {
      this.$store.dispatch('user/seenConversation', selected._id);
    }
    this.$store.dispatch('company/getInfo');
  },
  updated() {
    const element = document.querySelector('.over-cont-message');
    if (element) {
      element.scrollTop = 0;
      setTimeout(() => {
        element.scrollTop = element.scrollHeight;
        setTimeout(() => {
          const ps = new PerfectScrollbar('#scroll-over-cont-message', {
            wheelPropagation: false,
          });
          ps.update();
        }, 150);
      }, 10);
    }
  },
  beforeDestroy() {
    this.$store.dispatch('user/selectConversation', false);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.right-head{
  .btn-green{
    margin-right: 3vh !important;
  }
  .global-param{
    top: 1.4vh;
  }
}
.message-search{
  input{
    padding-left: 2vw;
  width: calc(100% - 2vw) !important;
  }
  .picto-search{
    bottom: .8vh;
  }
}

</style>
